import { ColumnSpans } from "@onnit-js/ui/components/module/Cards";
import {
    GridGapSizes,
    GridNumColumns,
    ProductGridItemVariants
} from "@onnit-js/ui/components/module/grid/product/ProductGrid";
import { PageSectionProps } from "@onnit-js/ui/components/module/PageSection";
import { Document } from "@contentful/rich-text-types";
import { TypeStyles } from "@onnit-js/ui/components/text/textVariants";
import { SignUpAreaProps } from "../components/common/SignUpArea";

export enum ContentTypeId {
    HERO = "hero",
    FEATURE = "feature",
    CARDS = "cards",
    CARD = "card",
    PAGE_HEADER = "pageHeader",
    PAGE_SECTION = "pageSection",
    MEDIA_LIST = "mediaList",
    MEDIA_LIST_ITEM = "mediaListItem",
    FLASH_SALE_BANNER = "flashSaleBanner",
    IMAGE_CONTAINER = "imageContainer",
    GRID = "grid",
    GRID_ITEM = "gridItem",
    TEXT_LIST = "textList",
    TEXT_CONTAINER = "textContainer",
    SIGN_UP_AREA = "signUpArea",
    AMAZON_PERSONALIZE_HOME_PAGE = "amazonPersonalizeHomePage",
}

export interface ContentNode<ID, T> {
    typeId: ID;
    data: T;
}

export type HeroNode = ContentNode<ContentTypeId.HERO, Banner>;
export type FeatureNode = ContentNode<ContentTypeId.FEATURE, Banner>;
export type ContentCardsNode = ContentNode<ContentTypeId.CARDS, ContentCardCollection>;
export type ContentCardNode = ContentNode<ContentTypeId.CARD, ContentCard>;
export type PageHeaderNode = ContentNode<ContentTypeId.PAGE_HEADER, PageHeader>;
export type PageSectionNode = ContentNode<ContentTypeId.PAGE_SECTION, PageSection>;
export type MediaListNode = ContentNode<ContentTypeId.MEDIA_LIST, MediaList>;
export type MediaListItemNode = ContentNode<ContentTypeId.MEDIA_LIST_ITEM, MediaListItem>;
export type FlashSaleBannerNode = ContentNode<ContentTypeId.FLASH_SALE_BANNER, FlashSaleBanner>;
export type ImageContainerNode = ContentNode<ContentTypeId.IMAGE_CONTAINER, ImageContainer>;
export type GridNode = ContentNode<ContentTypeId.GRID, Grid>;
export type GridItemNode = ContentNode<ContentTypeId.GRID_ITEM, GridItem>;
export type TextListNode = ContentNode<ContentTypeId.TEXT_LIST, TextList>;
export type TextContainerNode = ContentNode<ContentTypeId.TEXT_CONTAINER, TextContainer>;
export type SignUpAreaNode = ContentNode<ContentTypeId.SIGN_UP_AREA, SignUpAreaProps>;

export type PageContentNodes =
    | PageHeaderNode
    | PageSectionNode
    | HeroNode
    | FeatureNode
    | ContentCardsNode
    | MediaListNode
    | ImageContainerNode
    | TextListNode
    | TextContainerNode
    | SignUpAreaNode;

export type PageSectionNodes = Exclude<PageContentNodes, PageHeaderNode | PageSectionNode>;

export type ValidPageContentTypes =
    | PageHeader
    | PageSection
    | Banner
    | ContentCardCollection
    | MediaList
    | FlashSaleBanner
    | ImageContainer
    | TextList
    | TextContainer
    | SignUpAreaProps;

export interface ContentImage {
    src: string;
    alt: string;
    height: number;
    width: number;
}

export interface ContentButton {
    text?: string;
    icon?: string;
    color?: string;
    textColor?: string;
}

export interface PageHeader {
    backgroundImage: ContentImage;
    foregroundImage?: ContentImage;
    hiddenHeading: string;
}

export interface PageSection extends PageSectionProps {
    boxProps?: [];
    content: PageSectionNodes[];
}

export interface Banner {
    variant?: string | null;
    title?: string | null;
    image?: ContentImage;
    mobileImage?: ContentImage;
    body?: [] | null;
    cta?: string | null;
    url?: string | null;
}

export interface ContentCardCollection {
    title?: string;
    cards?: ContentCardNode[];
}

export interface ContentCard {
    text?: string;
    url?: string;
    urlDescription?: string;
    target: "_blank" | "_self";
    cta?: string;
    justifyContent?: string;
    columnSpan?: ColumnSpans;
    image?: ContentImage;
    overlayAlpha?: number;
    lazyLoad?: boolean;
}

export interface MediaListItem {
    title: string;
    titleColor?: string;
    url?: string;
    image?: ContentImage;
    borderColor?: string;
    backgroundColor?: string;
    showButton: boolean;
    buttonBackgroundColor?: string;
    buttonIconColor?: string;
}

export interface MediaList {
    columnCount?: number;
    items: MediaListItemNode[];
}

export interface FlashSaleBanner {
    variant: "solid" | "outline";
    backgroundColor?: string;
    headline: string;
    textColor?: string;
    title: string;
    body?: string;
    image: ContentImage;
    url: string;
    urlDescription: string;
    cta: string;
    isActive: boolean;
}

export interface ImageContainer {
    image: ContentImage;
    lazyLoad?: boolean;
    boxProps?: [];
}

export interface Grid {
    gridGapSize: GridGapSizes;
    columnCount: GridNumColumns;
    items: GridItemNode[];
}

export interface GridItem {
    image: ContentImage;
    button: ContentButton;
    title: string;
    titleColor?: string;
    body?: string;
    bodyColor?: string;
    url: string;
    variant: ProductGridItemVariants;
    backgroundColor?: string;
}

export interface TextList {
    items: string[];
}

export interface TextContainer {
    textColor?: string;
    typeStyle?: TypeStyles;
    textAlign?: "left" | "center" | "right";
    content: Document | null;
    boxProps?: [];
}
