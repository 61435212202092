import React, { CSSProperties, ImgHTMLAttributes, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useTheme } from "styled-components";
import { lazyOptions, placeholderSrc } from "./shared";

/**
 * Picture Element.
 * Wraps native picture and maps theme.breakpoints to <source>
 */
interface PictureProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> {
    src: string[];
}

const defaultStyles = {
    display: "block",
    width: "100%",
    height: "auto",
};

const Picture = ({ src, alt, style, ...rest }: PictureProps) => {
    const theme = useTheme();
    const BPs = theme.breakpoints.map((str: string) => parseInt(str.replace("px", "")));
    const mobile = src[0];

    return (
        <picture>
            <source media={`(max-width:${BPs[0] - 1}px)`} srcSet={mobile} />
            {src.slice(1).map((url, index) => {
                const min = BPs[index];
                let max;
                let mq;
                if (index < src.length - 1) {
                    max = BPs[index + 1] - 1;
                    mq = `(min-width: ${min}px) and (max-width: ${max}px)`;
                } else {
                    max = BPs[index];
                    mq = `(min-width: ${min}px)`;
                }
                return <source key={url} media={mq} srcSet={url} />;
            })}

            {/* Fallback img element required */}
            <img src={src[src.length - 1]} alt={alt} style={{ ...defaultStyles, ...style }} {...rest} />
        </picture>
    );
};

type LazyPictureProps = PictureProps;

export const LazyPicture = ({ style, alt, width, height, ...rest }: LazyPictureProps) => {
    const [ref, inView] = useInView(lazyOptions);
    const [isLoaded, setLoaded] = useState(false);

    return (
        <div
            ref={ref}
            style={{
                position: "relative",
                ...style,
            }}
        >
            {inView ? (
                <Picture
                    alt={alt}
                    {...rest}
                    onLoad={inView ? () => setLoaded(true) : () => {}} /* eslint-disable-line @typescript-eslint/no-empty-function */
                    style={{
                        ...defaultStyles,
                        transition: "opacity 300ms",
                        opacity: isLoaded ? 1 : 0,
                        ...style,
                    }}
                    width={width}
                    height={height}
                />
            ) : (
                <img
                    alt={alt}
                    src={placeholderSrc([width, height])}
                    width={width}
                    height={height}
                    style={defaultStyles}
                />
            )}
        </div>
    );
};

interface BaseProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> {
    src: string[];
}

interface PicProps extends BaseProps {
    lazyLoad: false;
}

interface LazyPicProps extends Omit<BaseProps, "width" | "height"> {
    lazyLoad: true;
    width: CSSProperties["width"];
    height: CSSProperties["height"];
}

type EitherPicProps = PicProps | LazyPicProps;

export default ({ lazyLoad, ...props }: EitherPicProps) => (lazyLoad ? <LazyPicture {...props} />
    : <Picture {...props} />);
