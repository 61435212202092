import React from "react";
import base from "../themes/base";
import { Box, BoxProps } from "../box/Box";
import { Text } from "../text/Text";

export interface PageSectionProps extends BoxProps {
    heading?: string;
    headingColor?: string;
    body?: string;
    bodyColor?: string;
    textAlign?: "left" | "center";
    isScreenReaderOnly?: boolean;
    boxProps?: [];
}

const PageSection: React.FC<React.PropsWithChildren<PageSectionProps>> = (
    {
        heading,
        isScreenReaderOnly = false,
        headingColor,
        body,
        bodyColor,
        textAlign = "center",
        borderColor,
        children,
        ...rest
    }) => {
    const borderProps = borderColor
        ? {
            borderColor,
            borderTop: "1px solid",
            borderBottom: "1px solid",
        }
        : {};

    return (
        <Box as="section" className="oui-page-section" py={[6, 6, 6, 7]} px={4} {...borderProps} {...rest}>
            {heading && (
                <Text
                    className={isScreenReaderOnly ? "sr-only" : ""}
                    as="h2"
                    typeStyle="title02"
                    textAlign={textAlign}
                    color={headingColor}
                    mb={heading && !body ? 6 : 0}
                >
                    {heading}
                </Text>
            )}
            {body && (
                <Text
                    as="p"
                    typeStyle="body"
                    textAlign={textAlign}
                    color={bodyColor}
                    maxWidth="640px"
                    mt={2}
                    mx={textAlign === "center" ? "auto" : undefined}
                    mb={6}
                >
                    {body}
                </Text>
            )}

            {children && (
                <Box maxWidth={base.gridMaxWidth} mx="auto" position="relative">
                    {children}
                </Box>
            )}
        </Box>
    );
};

export default PageSection;
