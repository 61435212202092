import React, { ReactNode } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Box from "@onnit-js/ui/components/box/Box";
import { Block, BLOCKS, Document, Inline } from "@contentful/rich-text-types";
import { Text } from "@onnit-js/ui/components/text/Text";
import { TextContainer } from "../../interfaces/SiteContent";
import { renderImageNode, renderTextNode } from "./RichTextMapping";

interface Props {
    data: TextContainer;
}

const makeComponent = (el: string, { textColor, ...rest }: any) => (
    node: Block | Inline,
    children: ReactNode
): ReactNode => (
    <Text as={el} color={textColor} {...rest}>
        {children}
    </Text>
);

const ContentfulTextContainer = ({ data: { content, boxProps, ...textProps } }: Props) => (
    <Box {...boxProps}>
        <>
            {documentToReactComponents(content as Document, {
                renderNode: {
                    [BLOCKS.EMBEDDED_ASSET]: renderImageNode,
                    [BLOCKS.HEADING_1]: makeComponent("h1", { ...textProps, mb: 4 }),
                    [BLOCKS.HEADING_2]: makeComponent("h2", { ...textProps, mb: 4 }),
                    [BLOCKS.HEADING_3]: makeComponent("h3", { ...textProps, mb: 4 }),
                    [BLOCKS.HEADING_4]: makeComponent("h4", { ...textProps, mb: 4 }),
                    [BLOCKS.HEADING_5]: makeComponent("h5", { ...textProps, mb: 4 }),
                    [BLOCKS.HEADING_6]: makeComponent("h6", { ...textProps, mb: 4 }),
                    [BLOCKS.PARAGRAPH]: makeComponent("p", { ...textProps, mb: 2 }),
                },
                renderText: renderTextNode,
            })}
        </>
    </Box>
);

export default ContentfulTextContainer;
