import React, { ReactNode, useEffect, useRef } from "react";
import FocusLock from "react-focus-lock";
import styled, { css } from "styled-components";
import { CSSTransition } from "react-transition-group";
import { ifProp, theme } from "styled-tools";
// eslint-disable-next-line import/no-unresolved
import { ReactFocusLockProps } from "react-focus-lock/interfaces";
import Portal from "../portal/Portal";
import { Box } from "../box/Box";

export interface ModalPortalProps {
    isOpen: boolean;
    maxWidth?: string | string[] | number | number[];
    onRequestClose?: Function;
    closeOnOverlayClick?: boolean;
    children?: ReactNode | ReactNode[];
    "aria-labelledby"?: string;
    "aria-describedby"?: string;
    focusLockProps?: ReactFocusLockProps;
    fullScreen?: boolean;
}

const Panel = styled(Box)<{ fullScreen: boolean }>`
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    background: white;
    position: relative;
    z-index: 1;

    ${ifProp(
    "fullScreen",
    css`
            height: 100%;
        `,
    css`
            padding: ${theme("space.5")}px;
            border-radius: 4px;
            box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.25);
        `
)};
`;

const ease = "cubic-bezier(0,0,0,1)";

const Container = styled.div<{ fullScreen: boolean }>`
    box-sizing: border-box;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 20000001;
    background: rgba(0, 0, 0, 0.5);
    -webkit-overflow-scrolling: touch;
    overflow: auto;

    ${ifProp(
    "fullScreen",
    css`
            .oui-modal__focuslock {
                height: 100vh;
                overflow: hidden;
            }
        `,
    css`
            padding: ${theme("space.5")}px;
        `
)};

    &.slide-enter {
        opacity: 0;
    }

    &.slide-enter .oui-modal__panel {
        transform: translateY(120px);
    }

    &.slide-enter-active {
        opacity: 1;
        transition: opacity 350ms ${ease};
    }

    &.slide-enter-active .oui-modal__panel {
        transform: translateY(0);
        transition: transform 300ms ${ease};
    }

    &.slide-exit {
        opacity: 1;
    }

    &.slide-exit .oui-modal__panel {
        transform: translateY(0);
    }

    &.slide-exit-active {
        opacity: 0;
        transition: opacity 200ms ${ease};
    }

    &.slide-exit-active .oui-modal__panel {
        transform: translateY(120px);
        transition: transform 300ms ${ease};
    }
`;

interface ExtendedModalPortalProps extends ModalPortalProps {
    uuid: string;
}

export default function ModalPortal(props: ExtendedModalPortalProps) {
    const {
        isOpen,
        children,
        maxWidth = "600px",
        onRequestClose,
        closeOnOverlayClick = true,
        fullScreen = false,
        uuid,
    } = props;

    const nodeRef = useRef(null);

    useEffect(() => {
        document.body.style.overflow = isOpen ? "hidden" : "auto";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);
    const handleOverlayClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event.target === event.currentTarget && onRequestClose && closeOnOverlayClick) {
            onRequestClose();
        }
    };

    return (
        <CSSTransition nodeRef={nodeRef} in={isOpen} timeout={350} classNames="slide" mountOnEnter unmountOnExit>
            <Portal>
                <Container ref={nodeRef} fullScreen={fullScreen} onClick={handleOverlayClick}>
                    <Panel
                        role="dialog"
                        aria-labelledby={props["aria-labelledby"] ?? uuid}
                        aria-describedby={props["aria-describedby"]}
                        className="oui-modal__panel"
                        maxWidth={fullScreen ? "100%" : maxWidth}
                        fullScreen={fullScreen}
                    >
                        <FocusLock {...props.focusLockProps} className="oui-modal__focuslock">
                            {children}
                        </FocusLock>
                    </Panel>
                </Container>
            </Portal>
        </CSSTransition>
    );
}
