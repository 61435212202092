import React from "react";
import { Box, BoxProps } from "../box/Box";
import useAddToBag from "../../hooks/useAddToBag";
import Product from "../../@types/interfaces/product/Product";
import ProductImage from "../image/ProductImage";
import UrlUtil from "../../utils/UrlUtil";
import RecommendationItemPrice from "./RecommendationItemPrice";
import RecommendationItemProductName from "./RecommendationItemProductName";
import Button, { ButtonProps } from "../button/Button";

interface ProductCTAProps {
    product: Product
}

const ProductCTA: React.FC<React.PropsWithChildren<ProductCTAProps>> = ({ product }) => {
    const { product_id, is_apparel } = product;
    const { addToBag, isAddingToBag } = useAddToBag();
    const url = UrlUtil.getProductUrl(product);

    const sharedProps: ButtonProps = {
        display: "block",
        width: 1,
        size: "medium",
        mb: 2,
    };

    if (is_apparel && url) {
        return <Button el="a" href={url} {...sharedProps}>View Sizes</Button>;
    }

    return (
        <Button
            {...sharedProps}
            disabled={isAddingToBag}
            onClick={() => addToBag({
                product_id,
                quantity: 1,
                subscription_interval: null,
            })}
        >
            {isAddingToBag ? "Adding..." : "Add to Bag"}
        </Button>
    );
};

interface RecommendationItemProps extends BoxProps {
    item: Product,
    onClick: (event: any) => void;
}

const RecommendationItem: React.FC<React.PropsWithChildren<RecommendationItemProps>> = ({ item, onClick, ...rest }) => {
    const { product_name, authority_image_url } = item;
    const url = UrlUtil.getProductUrl(item);

    return (
        <Box
            display={["block", "block", "flex"]}
            py={2}
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="grays.1"
            alignItems="center"
            {...rest}
        >
            <Box display="flex" flex="1 1 auto" alignItems="center" mb={[2, 2, 0]}>
                <ProductImage
                    productUrl={url}
                    imageUrl={authority_image_url}
                    productName={product_name}
                    width="100px"
                    onClick={onClick}
                />

                <Box flex="1 1 auto" p={2}>
                    <RecommendationItemProductName item={item} />
                    <RecommendationItemPrice item={item} />
                    <Box mt={2} width="190px">
                        <ProductCTA product={item} />
                    </Box>
                </Box>
            </Box>

        </Box>
    );
};

export default RecommendationItem;
