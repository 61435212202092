import React, { useState } from "react";
import { Text } from "@onnit-js/ui/components/text";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import styled from "styled-components";
import { FiChevronRight } from "react-icons/fi";
import Icon from "@onnit-js/ui/components/icon/Icon";

const QuestionButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    text-align: left;
`;

interface QuestionProps {
    question: string;
    isOpen: boolean;
    answerId: string;
    onToggle?: any;
    textColor?: string;
}

const Question = ({ question, isOpen, answerId, onToggle, textColor = "grays.5" }: QuestionProps) => (
    <dt key={answerId}>
        <Box mt={[3, 3, 5]}>
            <QuestionButton
                className="FAQ__question"
                aria-expanded={isOpen}
                aria-controls={answerId}
                onClick={onToggle}
            >
                <Icon
                    icon={FiChevronRight}
                    color="black"
                    style={{
                        transition: "all 0.2s linear",
                        transform: isOpen ? `rotate(90deg)` : `rotate(0deg)`,
                    }}
                />
                <Text
                    as="span"
                    color={textColor}
                    typeStyle="default"
                    verticalAlign="middle"
                    dangerouslySetInnerHTML={{ __html: question }}
                    display="inline"
                />
            </QuestionButton>
        </Box>
    </dt>
);

interface AnswerProps {
    answer: string;
    id: string;
    isOpen: boolean;
    textColor?: string;
}

const Answer = ({ answer, id, isOpen, textColor = "grays.5" }: AnswerProps) => {
    const answerDisplay = isOpen ? "block" : "none";

    return (
        <dd key={id}>
            <Box mt={2}>
                <Text
                    as="p"
                    pl={3}
                    pt={2}
                    color={textColor}
                    typeStyle="default"
                    style={{ display: answerDisplay }}
                    id={id}
                    dangerouslySetInnerHTML={{ __html: answer }}
                />
            </Box>
        </dd>
    );
};

interface FaqProps {
    faq: Faq;
    index: number;
    onToggle: any;
    isOpen: any;
    answerColor?: string;
    questionColor?: string;
}

const FaqItem = ({ faq, index, onToggle, isOpen, answerColor = "grays.5", questionColor = "black" }: FaqProps) => (
    <>
        <Question
            textColor={questionColor}
            question={faq.question}
            isOpen={isOpen}
            onToggle={onToggle}
            answerId={`question${index}`}
            key={`q${index}`}
        />
        <Answer
            textColor={answerColor}
            isOpen={isOpen}
            answer={faq.answer}
            id={`question${index}`}
            key={`a${index}`}
        />
    </>
);

export interface Faq {
    question: string;
    answer: string;
}

interface FaqsProps extends BoxProps {
    faqs: Faq[];
    questionColor?: string;
    answerColor?: string;
}

export default function Faqs({ faqs, questionColor, answerColor, ...rest }: FaqsProps) {
    const [open, setIsOpen] = useState([]);
    const isOpen = (index: never): boolean => Boolean(open.includes(index));

    const onToggle = (index: never) => {
        if (open.includes(index)) {
            setIsOpen(open.filter((item) => item !== index));
        } else {
            setIsOpen([...open, index]);
        }
    };
    return (
        <Box maxWidth="960px" mx="auto" {...rest}>
            <Box px={4}>
                <Text as="p" textAlign="center" textTransform="uppercase" typeStyle="copyHero02">
                    Frequently Asked Questions
                </Text>
                <dl>
                    {faqs.map((faq, index) => (
                        <FaqItem
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={index}
                            faq={faq}
                            questionColor={questionColor}
                            answerColor={answerColor}
                            index={index}
                            isOpen={isOpen(index as never)}
                            onToggle={() => onToggle(index as never)}
                        />
                    ))}
                </dl>
            </Box>
        </Box>
    );
}
