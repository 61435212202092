import React from "react";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text";
import { Img } from "@onnit-js/ui/components/image";

interface Props extends BoxProps {
    headlineColor?: string;
}

const Logo = ({ src, alt }: { src: string; alt: string }) => (
    <Box p={3} width={["70%", "70%", "20%"]} maxWidth={200}>
        <Img lazyLoad={false} src={src} alt={alt} />
    </Box>
);
const Social = ({ src, label }: { src: string; label: string }) => (
    <Box
        px={3}
        py={6}
        mb={[5, 5, 0]}
        background={`url(${src}) no-repeat center`}
        backgroundSize="auto 100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
    >
        <Text color="sunwashGold" fontWeight="heavy" fontSize={3}>
            {label}
        </Text>
    </Box>
);

export default function AsSeenIn({ bg = "black", headlineColor = "#54C2B7", ...rest }: Props) {
    const onnitContext = useOnnitContext();

    if (!onnitContext) {
        return null;
    }

    const IMG_URL = `${onnitContext.url.cdn.static_images}/offers/shared`;

    return (
        <Box bg={bg} py={[6, 6, 7]} px={5} overflow="hidden" {...rest}>
            <Box maxWidth="1680px" mx="auto">
                <Text
                    textTransform="uppercase"
                    fontWeight="black"
                    color={headlineColor}
                    textAlign="center"
                    letterSpacing={5}
                    mt={[3, 3, 0]}
                    mb={[4, 4, 6]}
                >
                    As Seen In
                </Text>
                <Box
                    display="flex"
                    justifyContent={["center", "center", "space-between"]}
                    alignItems="center"
                    flexDirection={["column", "column", "row"]}
                    mb={6}
                >
                    <Logo src={`${IMG_URL}/menshealth.png`} alt="Menths Health logo" />
                    <Logo src={`${IMG_URL}/forbes.png`} alt="Forbes logo" />
                    <Logo src={`${IMG_URL}/musclefitness.png`} alt="Muscle and Fitness logo" />
                    <Logo src={`${IMG_URL}/businessinsider.png`} alt="Business Insider logo" />
                    <Logo src={`${IMG_URL}/mensjournal.png`} alt="Mens Journal Logo" />
                </Box>
                <Box
                    display="flex"
                    justifyContent={["center", "center", "space-around"]}
                    alignItems="center"
                    flexDirection={["column", "column", "row"]}
                >
                    <Social src={`${IMG_URL}/facebook.png`} label="635K+ Likes" />
                    <Social src={`${IMG_URL}/instagram.png`} label="915K+ Followers" />
                    <Social src={`${IMG_URL}/twitter.png`} label="160K+ Followers" />
                </Box>
            </Box>
        </Box>
    );
}
